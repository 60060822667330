import React, { useEffect } from "react";
import { Layout } from "../../components/Layout";
import { MainSection } from "../../style/notifications";
import NotificationsPanel from "../../components/NotificationsPanel";
import { fetchPatients, fetchDoctors } from "../../state";
import { connect, useDispatch } from "react-redux"

const Notifications = ({
    user,
    doctorsList,
    patientsList,
}) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPatients(user));
        dispatch(fetchDoctors(user))
    }, [user])

    return (
        <Layout>
            <MainSection>
                <h1>Notifications</h1>
                <NotificationsPanel patients={patientsList} doctors={doctorsList} />
            </MainSection>
        </Layout>
    )
}

const mapStateToProps = (state) => {
    return {
        patientsList: state.patients.patients,
        doctorsList: state.doctors.doctors,
        user: state.login.user,
    }
}

export default connect(mapStateToProps)(Notifications);