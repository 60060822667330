import React, { useState, useEffect } from "react";
import { RootSection, CardSection, AddForm } from "../style/notifications";
import CardContainer from "./Card";
import Axios from "axios";

const NotificationsPanel = ({
    patients,
    doctors,
}) => {
    const [state, setState] = useState({})
    const [enable, setEnable] = useState(false)
    const [patientList, setPatientList] = useState([])
    const [doctorList, setDoctorList] = useState([])

    useEffect(() => {
        let patientList = patients.filter(p => p.hasOwnProperty("fcmToken"));
        let doctorList = doctors.filter(d => d.hasOwnProperty("fcmToken"));
        setPatientList(patientList);
        setDoctorList(doctorList);
    }, [patients, doctors])

    const handleChange = (e) => {
        // console.log(e)
        setState({ ...state, [e.target.name]: e.target.value })
        setEnable(true)
    }

    const createOptions = (inputArray) => {
        console.log("inputArray", inputArray)
        return inputArray.map((item) => (
            <option value={Number(item.fcmToken)} key={item.fcmToken}>
                {item.firstName} {item.lastName}
            </option>
        ))
    }

    const handleChangePatientSelection = (e) => {
        setState({ ...state, [e.target.name]: Number(e.target.value) })
        setEnable(true)
    }

    const handleChangeDoctorSelection = (e) => {
        setState({ ...state, [e.target.name]: Number(e.target.value) })
        setEnable(true)
    }

    const handlePatientSubmit = (event) => {
        // event.preventDefault();
        // console.log("State Patient", state);
        if(!state.patientTitle){
            return alert("Enter title please");
        }
        if(!state.patientDesc){
            return alert("Enter description please");
        }
        let registration_ids = [];
        patientList.map(obj => {
            registration_ids.push(obj.fcmToken);
        });
        let data = {
            "registration_ids": registration_ids,
            "notification": {
                "title": state.patientTitle,
                "body": state.patientDesc,
                "android_channel_id": "doctorite"
            },
            "data": {}
        };
        Axios({
            method: 'post', //you can set what request you want to be
            url: "https://fcm.googleapis.com/fcm/send",
            data: data,
            headers: {
                Authorization: "key=AAAAGACO46w:APA91bGAsXFBMM-yzAEjDs__Y3foBpM14KPBOHGCLOsdfUIDabNXf-_HTavVCOR2qP1iCk5ZEHObn1xE9W9TeWyjRjizTERqCbrPc-Hu5NW_80EMzh79avQ59IM1ryDkEoVkKbu8UoMT"
            }
        })
            .then(res => {
                alert("Notification Sent!")
            })
            .catch(err => {
                alert("Failed to send notification!")
            })
    }

    const handleDoctorSubmit = (event) => {
        // event.preventDefault();
        // console.log("State Doctor", state);
        if(!state.doctorTitle){
            return alert("Enter title please");
        }
        if(!state.doctorDesc){
            return alert("Enter description please");
        }
        let registration_ids = [];
        doctorList.map(obj => {
            registration_ids.push(obj.fcmToken);
        });
        let data = {
            "registration_ids": registration_ids,
            "notification": {
                "title": state.doctorTitle,
                "body": state.doctorDesc,
                // "android_channel_id": "doctorite"
            },
            "data": {}
        };
        Axios({
            method: 'post', //you can set what request you want to be
            url: "https://fcm.googleapis.com/fcm/send",
            data: data,
            headers: {
                Authorization: "key=AAAAMkp-TTk:APA91bErEvLQR9grTz48wtYuiWUxyHhzHwjDiyCpfYJdtVvjn0XRKJDuiUO9mGUVvtltWk5dDUV-KfFcrJGbVX0SrPJNtpn4y4B0eb9usyWWpkvJKa54TfkxlB0sE9JbWn_-dR3cifC3"
            }
        })
            .then(res => {
                alert("Notification Sent!")
            })
            .catch(err => {
                alert("Failed to send notification!")
            })
    }

    return (
        <RootSection>
            <CardSection>
                <CardContainer>
                    <h4>Patient App</h4>
                    <AddForm onSubmit={handlePatientSubmit}>
                        <label htmlFor="patientTitle">
                            Title
                            <input
                                id="patientTitle"
                                name="patientTitle"
                                type="text"
                                placeholder={"Title"}
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label htmlFor="patientDesc">
                            Description
                            <textarea
                                id="patientDesc"
                                name="patientDesc"
                                placeholder={"Description"}
                                onChange={handleChange}
                            >
                            </textarea>
                        </label>
                        {/* <br />
                        <label htmlFor="patientSelection">
                            Send to
                            <select
                                style={{
                                    backgroundColor: "none",
                                    borderColor: "gray",
                                    fontSize: ".9rem",
                                    height: "2rem",
                                    margin: ".5rem 0",
                                }}
                                name="patientSelection"
                                id="patientSelection"
                                defaultValue={0}
                                multiple
                                onChange={(e) => handleChangePatientSelection(e)}
                            >
                                <option>All</option>
                                {createOptions(patientList)}
                            </select>
                        </label> */}
                        <br />
                        <button
                            type="submit"
                            aria-disabled={enable ? true : false}
                            style={{
                                borderRadius: "5px",
                                backgroundColor: "green",
                                backgroundColor: enable ? "green" : "lightgray",
                                cursor: enable ? "pointer" : "default",
                            }}
                        >
                            Send Notification
                        </button>
                    </AddForm>
                </CardContainer>
                <CardContainer>
                    <h4>Doctor App</h4>
                    <AddForm onSubmit={handleDoctorSubmit}>
                        <label htmlFor="doctorTitle">
                            Title
                            <input
                                id="doctorTitle"
                                name="doctorTitle"
                                type="text"
                                placeholder={"Title"}
                                onChange={handleChange}
                            />
                        </label>
                        <br />
                        <label htmlFor="doctorDesc">
                            Description
                            <textarea
                                id="doctorDesc"
                                name="doctorDesc"
                                placeholder={"Description"}
                                onChange={handleChange}
                            >
                            </textarea>
                        </label>
                        <br />
                        {/* <label htmlFor="doctorSelection">
                            Send To
                            <select
                                style={{
                                    backgroundColor: "none",
                                    borderColor: "gray",
                                    fontSize: ".9rem",
                                    height: "2rem",
                                    margin: ".5rem 0",
                                }}
                                name="doctorSelection"
                                id="doctorSelection"
                                defaultValue={0}
                                multiple
                                onChange={(e) => handleChangeDoctorSelection(e)}
                            >
                                <option>All</option>
                                {createOptions(doctorList)}
                            </select>
                        </label> */}
                        <br />
                        <button
                            type="submit"
                            aria-disabled={enable ? true : false}
                            style={{
                                borderRadius: "5px",
                                backgroundColor: "green",
                                backgroundColor: enable ? "green" : "lightgray",
                                cursor: enable ? "pointer" : "default",
                            }}
                        >
                            Send Notification
                        </button>
                    </AddForm>
                </CardContainer>
            </CardSection>
        </RootSection>
    )
}

export default NotificationsPanel;