import styled from "styled-components"
import { Section } from "./globalStyles"

export const DashboardSection = styled.div`
  display: block;
  margin: 2rem;
  padding: 0;
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`

export const RootSection = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 2rem;
`

export const CardSection = styled.div`
  ${tw`flex flex-wrap overflow-hidden justify-around`};
`

export const Card = styled.div`
  ${tw`my-4 p-8 w-full overflow-hidden lg:w-2/5 xl:w-2/5`};
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  border-radius: 5px;
`

export const ChartSection = styled.div`
  ${tw`flex`};
`

export const TabsSection = styled.div`
  ${tw`flex flex-row p-1 self-end`};
  background-color: #ededed;
  width: fit-content;
  border-radius: 5px;
  margin-left: auto; 
  margin-right: 3rem;
`

export const Tab = styled.div`
  ${tw`px-2 py-1 text-base text-black text-center cursor-pointer`};
  width: 100px;
`

export const SelectedTab = {
  backgroundColor: "#fff",
  boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
  borderRadius: "5px"
}

export const DropDownWrapper = styled.div`
  ${tw`flex justify-center items-center`}
  height: 40px;
  margin-bottom: 10px;
`

export const DropDownSelect = styled.select`
  ${tw`px-4 text-base`}
  height: 90%;
  background-color: #fff;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.4);
  border: none;
  border-radius: 5px;
  outline:0px;
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #fff;
  -webkit-appearance: none;
  background-position-x: 98%;
`

export const DropDownOption = styled.option`
  ${tw`p-4`}
  background-color: #fff;
`

export const RangePickerModal = styled.div`
  ${tw`absolute z-50 p-4 overflow-hidden bg-white`}
  border-radius: 5px;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`

export const DoneButton = styled.div`
${tw`px-4 mt-2 flex text-base text-center justify-center items-center`}
height: 40px;
background-color: #fff;
box-shadow: 0 0 5px 0 rgba(0,0,0,0.4);
border: none;
border-radius: 5px;
cursor: pointer;
`

export const TabsSectionCard = styled.div`
  ${tw`flex flex-row p-1`};
  background-color: #ededed;
  width: fit-content;
  border-radius: 5px;
  margin-bottom: 10px;
`