import styled from "styled-components"
import { Section } from "./globalStyles"

export const MainSection = styled.div`
  display: block;
  margin: 2rem;
  padding: 0;
  h1 {
    font-size: 1.7rem;
  }
  h2 {
    font-size: 1.5rem;
  }
`

export const RootSection = styled.div`
  height: 100%;
  width: 100%;
  margin-top: 2rem;
`

export const CardSection = styled.div`
  ${tw`flex flex-wrap overflow-hidden justify-around`};
`

export const Form = styled.form`
  width: 100%;
  margin: 0rem 0 0 0;
  display: grid;
  grid-template-columns: 1fr 8rem;
  grid-gap: 0rem;

  label {
    height: fit-content;
    width: 22rem;
    display: flex;
    flex-direction: column;
    margin-top: 0.8rem;
    font-size: 0.8rem;
    margin-right: 1.2rem;
    color: gray;
    margin-bottom: 1rem;
    div {
      color: black;
    }
  }

  select {
    font-size: 0.8rem;
    height: 2rem;
    box-sizing: border-box;
    border: none;
    background-color: white;
  }
  input,
  textarea {
    font-size: 0.9rem;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid lightsteelblue;
    height: 2.5rem;
    ::placeholder {
      color: gray;
      font-size: 0.9rem;
    }
  }
  textarea {
    padding-top: 0.7rem;
  }
  button {
    height: 2.5rem;
    width: 22rem;
    background-color: green;
    color: white;
    border: none;
    padding: 0rem 1rem;
    margin: 0rem 0;
    font-size: 0.9rem;
    cursor: pointer;
    :hover {
      opacity: 0.9;
    }
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    label {
      width: 49%;
      margin-right: 0.1rem;
    }
  }
`
export const AddForm = styled(Form)`
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"] {
    width: 100%;
    font-size: 1rem;
  }
  label {
    margin-right: 3rem;
    ::placeholder {
      padding-bottom: 1rem;
      color: black;
    }
  }
`